import React, { useState, useEffect, useCallback, Suspense } from "react";
import {
  Route,
  Switch,
  Redirect,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import { Capacitor } from "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import { PushNotifications } from "@capacitor/push-notifications";

import Api from "./api/Api";
import {
  getStorageToken,
  setStorageToken,
  removeStorageToken,
} from "./helpers/helpers";

import Home from "./screens/Home";
import Challenges from "./screens/Challenges";
import Proposal from "./screens/Proposal";
import ResidentGuide from "./screens/ResidentGuide";
import Achievements from "./screens/Achievements";
import Events from "./screens/Events/Events";
import Settings from "./screens/Settings";

import ResetPassword from "./components/ResetPassword/ResetPassword";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ChangeComplex from "./components/ChangeComplex/ChangeComplex";
import Note from "./components/Note/Note";
import Spinner from "./components/Spinner/Spinner";
import Login from "./components/Login/Login";
import Header from "./components/Header/Header";
import MainContent from "./components/MainContent/MainContent";
import NavBar from "./components/NavBar/NavBar";
import AnimatedSwitch from "./components/AnimatedSwitch/AnimatedSwitch";
import SecurityContext from "./components/Context/SecurityContext";
import AppUrlListener from "./AppUrlListener";

import { FiBookOpen, FiHome, FiUsers, FiFeather } from "react-icons/fi";

import "./styles/global.scss";
import AnnouncementLinkPage from "./components/AnnouncementLinkPage/AnnouncementLinkPage";
import MyProposalForm from "./components/MyProposalForm/MyProposalForm";
import WelcomeModal from "./components/WelcomeModal/WelcomeModal";
import store from './store'
import { Provider } from 'react-redux'
import { useDispatch } from 'react-redux'
import { setCurrentComplex } from './features/currentComplex/currentComplexSlice'

function App() {
  const [splashHidden, setSplashHidden] = useState(null);
  const [tokenData, setTokenData] = useState(false);
  const [loading, setLoading] = useState(true);
  const [api, setApi] = useState(null);
  const [user, setUser] = useState(null);
  const [displayInfoModal, setDisplayInfoModal] = useState(false);

  // User proposal
  const [proposalData, setProposalData] = useState({});

  useEffect(() => {
    if (!splashHidden) {
      SplashScreen.hide().then(() => setSplashHidden(true));
    }
  });

  useEffect(() => {
    console.log(process.env)
    let ignore = false;
    if (!ignore) {
      setLoading(true);
      getStorageToken()
        .then(res => {
          let val = null;
          if (res && res.accessToken) {
            val = Object.assign({}, res);
          }

          setTokenData(val);
          setLoading(false);
        })
        .catch(() => {
          setTokenData(null);
          setLoading(false);
        });
    }
    return () => (ignore = true);
  }, []);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      setApi(new Api(tokenData));
    }
    return () => (ignore = true);
  }, [tokenData]);

  useEffect(() => {
    let ignore = false;
    if (!ignore && api && tokenData) {
      api
        .validateToken()
        .then(({ data }) => {
          if (data && data.success) {
            setUser(data.data);
            if (data.data.seen_welcome_info_at) {
              return null;
            } else if (!data.data.seen_welcome_info_at) {
              setDisplayInfoModal(!displayInfoModal);
            }
          }
        })
        .catch(err => {
          if (err.message === "No access") {
            resetToken();
          }
        });
    }
    return () => (ignore = true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  function updateToken(newTokenData) {
    setTokenData(newTokenData);
    setStorageToken("tokenData", newTokenData);
    //localStorage.setItem("tokenData", JSON.stringify(newTokenData));
  }

  function resetToken() {
    setTokenData(null);
    removeStorageToken();
    //localStorage.removeItem("tokenData");
  }

  return (
    <Provider store={store}>
      <SecurityContext.Provider value={{ api, user }}>
        <Router>
          <AppUrlListener></AppUrlListener>
          <Switch>
            <Route path="/note">
              <Note />
            </Route>
            <Route path="/henkilotietojen-poistaminen">
              <Note accountDeletion={true} />
            </Route>
            <Route
              path="/reset-password"
              component={props => <ResetPassword {...props} />}
            />
            {tokenData === null && (
              <Suspense fallback={<Spinner>Ladataan kirjautumista…</Spinner>}>
                <Redirect to="/" />
                <Login updateToken={updateToken} />
              </Suspense>
            )}

            {(tokenData && user && api.accessToken) && (
              <AppContainer
                resetToken={resetToken}
                api={api}
                user={user}
                displayInfoModal={displayInfoModal}
                setDisplayInfoModal={setDisplayInfoModal}
                proposalData={proposalData}
                setProposalData={setProposalData}
              />
            )}
          </Switch>
        </Router>
      </SecurityContext.Provider>
    </Provider>
  );
}

function AppContainer(props) {
  const {
    resetToken,
    api,
    user,
    displayInfoModal,
    setDisplayInfoModal,
    proposalData,
    setProposalData,
  } = props;
  const [challengesHistory, setChallengesHistory] = useState(0);
  const [ownAchievementsHistory, setOwnAchievementsHistory] = useState(0);
  const [
    communityAchievementsHistory,
    setCommunityAchievementsHistory,
  ] = useState(0);
  const [pushToken, setPushToken] = useState(null);
  const [showSettings, setShowSettings] = useState(false);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch()

  let location = useLocation();
  const pageContext = getPageContext(location.pathname);

  useEffect(() => {
    if (pushToken && api) {
      api.updatePushNotificationToken(pushToken).then(res => {
        console.log("push success, message: ", res.message);
      });
    }
  }, [pushToken, api]);

  useEffect(() => {
    setLoading(true);
    let ignore = false;
    if (!ignore) {
      api
        .getProposal()
        .then(response => {
          if (response && response.data) {
            setProposalData({
              data: response.data,
              error: null,
            });
            setLoading(false);
          }
        })
        .catch(() => {
          setLoading(false);
          console.log("error");
        });
    }
    return () => (ignore = true);
  }, [api, setLoading, setProposalData]);

  useEffect(() => {
    api.getCurrentComplex()
      .then((res) => {
        dispatch(setCurrentComplex(res))
      })
  }, [])

  const initNotifications = useCallback(() => {
    if (Capacitor.isNativePlatform) {
      PushNotifications.checkPermissions().then(result => {
        if (result.receive === 'granted') {
          // Permission is already granted, we can register for push notifications
          PushNotifications.register();
        } else if (result.receive === 'prompt') {
          // Permission is not granted, we need to ask for it
          PushNotifications.requestPermissions().then(result => {
            if (result.granted) {
              // Register with Apple / Google to receive push via APNS/FCM
              PushNotifications.register();
            } else {
              // Show some error
              console.log(result);
            }
          });
        } else {
          // The permission is denied and cannot be requested.
          console.log('Push notifications permission is denied');
        }
      });

      PushNotifications.addListener("registration", token => {
        console.log("Push registration success, token: " + token.value);
        setPushToken(token.value);
      });

      // Some issue with our setup and push will not work
      PushNotifications.addListener("registrationError", error => {
        //  console.log("Error on registration: " + JSON.stringify(error));
      });

      // Show us the notification payload if the app is open on our device
      PushNotifications.addListener(
        "pushNotificationReceived",
        notification => {
          //alert("Push received: " + JSON.stringify(notification));
        }
      );

      // Method called when tapping on a notification
      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        notification => {
          //  alert("Push action performed: " + JSON.stringify(notification));
        }
      );
    }
  }, []);

  useEffect(() => {
    if (user && Capacitor.isNativePlatform) {
      initNotifications();
    }
  }, [user, initNotifications]);

  const navigationLinks = [
    { to: "/", title: "Koti", color: "teal", icon: <FiHome /> },
    {
      to: "/guide/open",
      title: "Opas",
      toptitle: "Löydä tietoja ja ohjeistuksia",
      color: "teal",
      icon: <FiBookOpen />,
      // icon: <FiBookOpen style={{ color: "#139FCB" }} />,
    },
    {
      to: "/community",
      title: "Oma talo",
      toptitle: "Osallistu talon toimintaan",
      color: "teal",
      icon: <FiUsers />,
      // icon: <FiUsers style={{ color: "#FD4F00" }} />,
    },
    {
      to: "/ekoekspertti/open-challenges",
      title: "EkoEkspertti",
      toptitle: "Haasta itsesi ekotekoihin",
      color: "teal",
      icon: <FiFeather />,
      // icon: <FiFeather style={{ color: "#00D7A7" }} />,
    },
  ];

  return (
    <Suspense fallback={<Spinner>Ladataan sivua…</Spinner>}>
      <WelcomeModal
        displayInfoModal={displayInfoModal}
        user={user}
        setDisplayInfoModal={setDisplayInfoModal}
      />
      <Switch>
        <Route exact path="/ekoekspertti">
          <Redirect to="/ekoekspertti/open-challenges" />
        </Route>
        {/* <Route exact path="/community">
          <Redirect to="/community" />
        </Route> */}
        <Route exact path="/achievements">
          <Redirect to="/ekoekspertti/achievements/own" />
        </Route>
      </Switch>
      <Header
        title={pageContext.title}
        color={pageContext.color}
        emblem={pageContext.emblem}
        showSettings={showSettings}
        setShowSettings={setShowSettings}
        items={navigationLinks}
        currentPage={pageContext.page}
      />
      <MainContent>
        <AnimatedSwitch
          keySlice={[0, 9]}
          animation="route-animation"
          timeout={300}
        >
          <Route path="/guide/open">
            <ResidentGuide />
          </Route>
          <Route path="/ekoekspertti/open-challenges">
            <Challenges
              pageContext={pageContext}
              activeTabIndex={0}
              user={user}
            />
          </Route>
          <Route path="/ekoekspertti/completed-challenges">
            <Challenges
              pageContext={pageContext}
              activeTabIndex={1}
              challengesHistory={challengesHistory}
              setChallengesHistory={setChallengesHistory}
              user={user}
            />
          </Route>
          <Route exact path={"/community/suggestions"}>
            <MyProposalForm proposalData={proposalData} />
          </Route>
          <Route exact path="/community">
            <Proposal
              pageContext={pageContext}
              activeTabIndex={0}
              loading={loading}
            />
          </Route>
          <Route path="/ekoekspertti/achievements/own">
            <Achievements
              pageContext={pageContext}
              activeTabIndex={0}
              ownAchievementsHistory={ownAchievementsHistory}
              setOwnAchievementsHistory={setOwnAchievementsHistory}
            />
          </Route>
          <Route path="/ekoekspertti/achievements/community">
            <Achievements
              pageContext={pageContext}
              activeTabIndex={1}
              communityAchievementsHistory={communityAchievementsHistory}
              setCommunityAchievementsHistory={setCommunityAchievementsHistory}
            />
          </Route>
          <Route exact path="/settings">
            <Settings pageContext={pageContext} resetToken={resetToken} />
          </Route>
          <Route path="/settings/change-password">
            <ChangePassword />
          </Route>
          <Route path="/settings/change-complex">
            <ChangeComplex />
          </Route>
          <Route exact path="/announcement-link/:id">
            <AnnouncementLinkPage />
          </Route>
          <Route exact path="/community/events">
            <Events />
          </Route>
          <Route path="/">
            <Home
              pageContext={pageContext}
              items={navigationLinks}
              currentPage={pageContext.page}
            />
          </Route>
        </AnimatedSwitch>
      </MainContent>
      <NavBar items={navigationLinks} currentPage={pageContext.page} />
    </Suspense>
  );
}

const getPageContext = location => {
  if (location.slice(0, 6) === "/guide") {
    return {
      page: "/guide",
      title: "Opas",
      // color: "yellow",
      emblem: false,
    };
  } else if (location.slice(0, 10) === "/community") {
    return {
      page: "/community",
      title: "Oma talo",
      // color: "orange",
      emblem: false,
    };
  } else if (location.slice(0, 9) === "/settings") {
    return {
      page: "/settings",
      title: "Asetukset",
      // color: "orange",
      emblem: false,
    };
  } else if (location.slice(0, 13) === "/ekoekspertti") {
    return {
      page: "/ekoekspertti",
      title: "Ekoekspertti",
      color: "blue",
      emblem: false,
    };
  } else {
    return { page: "/", title: "Heta", color: "teal", emblem: true };
  }
};

export default App;
